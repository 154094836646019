//Pagina a hacer: planes_workout
import * as React from "react"
import ContentPageLayout from "../../../../components/ContentPageLayout"
import { BiLeftArrowAlt } from "@react-icons/all-files/bi/BiLeftArrowAlt"
import { getPlan } from "../../../../api/content"
import DetailCard from "../../../../components/DetailsCard"
import { Link } from "gatsby"
import config from "../../../../config"

const DetailsPage = props => {
  // console.log(`Aqui es la pagina de detalles: `);
  // console.log(props);

  const PlanDetailsId = props.params.id
  const [detail, setDetail] = React.useState({})
  const [trainingsData, setTrainingsData] = React.useState([])

  React.useEffect(() => {
    getPlan(PlanDetailsId).then(data => {
      setDetail(data)
    })
  }, [props.params.id])

  React.useEffect(() => {
    setTrainingsData(detail.entrenamientos)
  }, [detail])

  return (
    <ContentPageLayout>
      {detail ? (
        <div className="pb-20">
          <div>
            <div
              className="flex relative"
              style={{ height: 240, width: "100%" }}
            >
              <img
                className="absolute"
                src={`${config.serverUri}/uploads/banner_superior_8acc5551bb.png`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={props.name}
              ></img>
              <div className="absolute flex p-5 h-full flex-col align-bottom">
                <h1 className="text-white ">{detail.nombre}</h1>
                <p className="text-white">
                  Aquí podrás encontrar tu rutina ideal de acuerdo al grupo
                  muscular que quieras trabajar y la intensidad adecuada para tu
                  nivel.
                </p>
              </div>
            </div>
          </div>

          {console.log(detail)}
          {/* <div className="h-64 grid grid-cols-3 grid-flow-row gap-4 px-12" > */}
          <div className="w-11/12 my-12 mx-auto flex items-center">
            <Link to={`/content/plan/${detail.id}`}>
              {" "}
              <BiLeftArrowAlt className="text-3xl" />{" "}
            </Link>
            <span className="pl-4">
              Entrenamiento / Planes / {detail.nombre}
            </span>
          </div>
          <div className="flex flex-wrap items-stretch px-12 justify-center w-11/12 my-0 mx-auto">
            {trainingsData
              ? trainingsData.map((card, key) => {
                  return <DetailCard key={key} dataCard={card} planName={detail.nombre} />
                })
              : null}
          </div>
        </div>
      ) : null}
    </ContentPageLayout>
  )
}

export default DetailsPage
