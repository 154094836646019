import * as React from "react"
import ContentPageLayout from "../../../components/ContentPageLayout"
import { Image } from "reactjs-media"
import { BiLeftArrowAlt } from "@react-icons/all-files/bi/BiLeftArrowAlt"
import { getPlan } from "../../../api/content"
import { Link } from "gatsby"
import DetailsPage from "./[id]/details"
import { SupervisedUserCircleOutlined } from "@material-ui/icons"
import { data } from "autoprefixer"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import config from "../../../config"

const PlanPage = ({ ...props }) => {
  const PlanId = props.params.id
  const [plan, setPlan] = React.useState({})
  const [planPortrait, setPlanPortrait] = React.useState()

  React.useEffect(() => {
    getPlan(PlanId).then(data => {
      if(data.portada){
        if(data.portada.url[0] !== '/'){
          setPlanPortrait(data.portada.url)
        }else{
          setPlanPortrait(`${config.serverUri}${data.portada.url}`)
        }
      }
      setPlan(data)
    })
  }, [props.params.id])

  console.log(plan)

  return (
    <ContentPageLayout>
    {console.log(plan)}
      {plan ? (
        <div className="pb-20">
          <div>
            <div
              className="flex relative"
              style={{ height: 240, width: "100%" }}
            >
              <img
                className="absolute"
                src={`${config.serverUri}/uploads/banner_superior_8acc5551bb.png`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={props.name}
              ></img>
              <div className="absolute flex p-5 h-full flex-col align-bottom">
                <h1 className="text-white ">Planes</h1>
                <p className="text-white">
                  Aquí podrás encontrar tu rutina ideal de acuerdo al grupo
                  muscular que quieras trabajar y la intensidad adecuada para tu
                  nivel.
                </p>
              </div>
            </div>
          </div>
          <div className="w-11/12 my-6 mx-auto flex items-center">
            <Link to={`/sections/entrenamiento`}>
              {" "}
              <BiLeftArrowAlt className="text-3xl" />{" "}
            </Link>
            <span className="pl-4">Entrenamiento / Planes </span>
          </div>
          <div className="w-10/12 mx-auto my-0">
            <div className="p-8">
              <h2 className="text-sc-primary">{plan.nombre}</h2>
            </div>
            <div className="flex flex-col justify-center w-full md:flex-row md:p-8 md:justify-center">
              <div className="w-11/12 mx-auto my-0 text-center">
                {plan && plan.portada && planPortrait ? (
                  <img
                    src={planPortrait}
                    alt={plan.portada.name}
                  />
                ) : (
                  <StaticImage
                    src={"../../../images/default_portada_plan.jpg"}
                    alt="default-image"
                  />
                )}
                <div className="flex w-full px-6 shadow mx-auto py-4 justify-around">
                  <div
                    className="flex h-full align-center"
                    style={{ fontSize: 16 }}
                  >
                    <div className="flex flex-col text-center">
                      <span className="text-black">Workouts</span>
                      {plan.entrenamientos != undefined ? (
                        <span>{plan.entrenamientos.length}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex h-full align-center">
                    <div className="flex flex-col text-center">
                      <span className="text-black">Duración</span>
                      <span>{plan.duracion} minutos</span>
                    </div>
                  </div>
                  <div className="flex h-full align-center">
                    <div className="flex flex-col text-center">
                      <span className="text-black">Objetivo</span>
                      <span>Pérdida de peso</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-8">
                <div className="mt-8 flex flex-col">
                  <div>Descripción del plan: {plan.descripcion}</div>
                  <span>{plan.instrucciones}</span>
                  <div className="flex justify-center">
                    {/* {console.log(`Aqui es el boton del plan a elegir`)}
                                        {console.log(plan)} */}
                    <Link
                      className="w-max text-center mt-14 bg-green-400 px-20 py-3 rounded-3xl text-black text-lg"
                      to={`/content/plan/${plan.id}/details`}
                      state={() => <DetailsPage plans={plan} />}
                    >
                      {" "}
                      Seleccionar Plan{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </ContentPageLayout>
  )
}

export default PlanPage
